import React from "react"
import styled from "styled-components"
import itemfiles from "./itemfiles.json"



const Container = styled.div`
    position: relative;
    max-width: 1200px;
    margin-bottom: 0 !important;
    margin: 200px auto;
    border: 2px solid rgba(0,0,0,0.2);
    h2{
        text-align: center;
        margin-bottom: 20px;
        padding: 10px;
        background-color: var(--green-primary);
    }
    div{
        padding: 20px;
    }

    div:nth-child(2n){
        background-color: rgba(0,0,0,0.05);
    }
    div:first-child{
        border-top: 2px solid rgba(0,0,0,0.05);
    }
`

const Item = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    h1{
        display: inline-block;
        padding: 0 20px;
    }
`

const Texten = () => {
    
    const Itemref = React.useRef(null);
    const items = itemfiles;

    const Checkfiles = (product) => {
        const id = product.id;
        const title  = product.title.content;
        const cpu = product.cpu.content;
        const ram = product.ram.content;
        const geheugen = product.geheugen.content;
        const scherm = product.schermdiameter.content;
        const gpu = product.gputype.content;
        const conditie = product.conditie.content.toLowerCase();


        if(title.includes("5280")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Deze refurbished laptop is uitermate geschikt voor professioneel gebruik. Uitgerust met een {cpu.split("@")[0]}, {ram} RAM en {geheugen}. Met een {gpu} kaart en een {scherm} scherm is deze {title.split("|")[0]} {conditie.toLowerCase()} en technisch in topconditie.

            </p>
            </div>)
        }
        else if(title.includes("5450")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Met een 7e generatie {cpu.split("@")[0]}, {ram} RAM en {geheugen} geheugen is deze refurbished laptop geschikt voor professioneel gebruik. Deze {title.split("|")[0]} beschikt over een {scherm} scherm met een {gpu} grafische kaart. Deze mooie laptop is {conditie.toLowerCase()} en klaar voor een tweede leven.

            </p>
            </div>)
        }
        else if(title.includes("5480") || title.includes("5490")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
               Met een {cpu.split("@")[0]}, {ram} RAM en {geheugen} geheugen is deze refurbished laptop geschikt voor professioneel gebruik. Deze {title.split("|")[0]} beschikt over een {scherm} scherm  met een {gpu} grafische kaart. Deze mooie laptop is {conditie.toLowerCase()} en klaar voor een tweede leven.

            </p>
            </div>)
        }
        else if(title.includes("7440") || title.includes("7240")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
               Met een {cpu.split("@")[0]}, {ram} RAM en {geheugen} geheugen is deze refurbished laptop geschikt voor professioneel gebruik. Deze {title.split("|")[0]} beschikt over een {scherm} scherm  met een {gpu} grafische kaart. Deze mooie laptop is {conditie.toLowerCase()} en klaar voor een tweede leven.

            </p>
            </div>)
        }
        else if(title.includes("5550")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Deze refurbished laptop is uitermate geschikt voor professioneel gebruik. Uitgerust met een {cpu.split("@")[0]}, {ram} RAM en {geheugen} geheugen. Met een {gpu} grafische kaart en een {scherm} full HD scherm is deze {title.split("|")[0]} {conditie.toLowerCase()}.

            </p>
            </div>)
        }
        else if(title.includes("560")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Deze refurbished laptop is uitermate geschikt voor professioneel gebruik. Uitgerust met een {cpu.split("@")[0]}, {ram} RAM en {geheugen} geheugen. Met een {gpu} grafische kaart en een {scherm} full HD scherm is deze {title.split("|")[0]} {conditie.toLowerCase()}.

            </p>
            </div>)
        }
        else if(title.includes("5568")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Deze refurbished laptop is uitermate geschikt voor professioneel gebruik. Uitgerust met een {cpu.split("@")[0]}, {ram} RAM en {geheugen} geheugen. Met een {gpu} grafische kaart en een {scherm} full HD scherm is deze {title.split("|")[0]} {conditie.toLowerCase()} en klaar voor gebruik.

            </p>
            </div>)
        }
                else if(title.includes("5580")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Deze refurbished laptop is uitermate geschikt voor professioneel gebruik. Beschikt over een {cpu.split("@")[0]}. Ook beschikt deze laptop over {ram} werkgeheugen. Uitgerust met een {scherm} full HD scherm, is de {title.split("|")[0]} {conditie.toLowerCase()} te bestellen en is de laptop technisch in topconditie.

            </p>
            </div>)
        }
        else if(title.includes("5590")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Met een {cpu.split("@")[0]}, {ram} RAM en {geheugen} geheugen is deze refurbished laptop geschikt voor professioneel gebruik. Deze {title.split("|")[0]}  beschikt over een {scherm} full HD scherm met een {gpu} grafische kaart. Deze refurbished laptop is {conditie.toLowerCase()} en technisch in topconditie.

                </p>
            </div>)
        }
        else if(title.includes("5590")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Met een {cpu.split("@")[0]}, {ram} RAM en {geheugen} geheugen is deze refurbished laptop geschikt voor professioneel gebruik. Deze {title.split("|")[0]} beschikt over een {scherm} full HD scherm met een {gpu} grafische kaart. Deze mooie laptop is {conditie.toLowerCase()} en klaar voor een tweede leven.

            </p>
            </div>)
        }
        else if(title.includes("430")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Met een {cpu.split("@")[0]}, {ram} RAM en {geheugen} geheugen is deze refurbished laptop geschikt voor professioneel gebruik. Deze {title.split("|")[0]} beschikt over een {scherm} full HD scherm met een {gpu} grafische kaart. Deze mooie laptop is {conditie.toLowerCase()} en klaar voor een tweede leven.

            </p>
            </div>)
        }
        else if(title.includes("450")){

            return(
            
            <div>
                 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Deze refurbished laptop is uitermate geschikt voor dagelijks gebruik. Deze {title.split("|")[0]} heeft een {cpu.split("@")[0]} processor. De laptop is standaard voorzien van {ram} RAM en {geheugen} geheugen. beschikt deze van een {scherm} scherm. Technisch is de laptop in topconditie. De laptop is verkrijgbaar {conditie.toLowerCase()}. 

            </p>
            </div>)
        }
        else if(title.includes("650")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Deze refurbished laptop is uitermate geschikt voor dagelijks gebruik. De {title.split("|")[0]} is te verkrijgen met een {cpu.split("@")[0]} processor. Het RAM werkgeheugen is {ram}. De laptop is voorzien van een {scherm} scherm en heeft {geheugen} geheugen. Technisch is de laptop in topconditie. De refurbished conditie is {conditie.toLowerCase()}.

            </p>
            </div>)
        }
        else if(title.includes("15U")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Een refurbished laptop geschikt voor dagelijks en professioneel gebruik. Deze {title.split("|")[0]} is uitgerust met een {cpu.split("@")[0]}, {geheugen} geheugen en {ram} RAM. Met een  {scherm} scherm en een {gpu} is deze laptop klaar voor dagelijks gebruik.

            </p>
            </div>)
        }
        else if(title.includes("14")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Deze refurbished laptop is uitermate geschikt voor professioneel gebruik. Uitgerust met een {cpu.split("@")[0]}, {ram} RAM en {geheugen}. Met een {gpu} kaart en een {scherm} scherm is deze {title.split("|")[0]} {conditie.toLowerCase()} en technisch in topconditie.

            </p>
            </div>)
        }
        else if(title.includes("0014DX")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Deze refurbished laptop is uitermate geschikt voor professioneel gebruik. Uitgerust met een {cpu.split("@")[0]}, {ram} RAM en {geheugen}. Met een {gpu} kaart en een {scherm} scherm is deze {title.split("|")[0]} {conditie.toLowerCase()} en technisch in topconditie.

            </p>
            </div>)
        }
        
        else if(title.includes("430")){

            return(
            
            <div> \
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Deze refurbished laptop is uitermate geschikt voor dagelijks gebruik. De {title.split("|")[0]} is te verkrijgen met een {cpu.split("@")[0]} processor. Het RAM werkgeheugen is {ram}. verder is deze laptop voorzien van {geheugen} geheugen en een {scherm} scherm. Technisch is de laptop in topconditie. De laptop is verkrijgbaar in de conditie {conditie.toLowerCase()}.

            </p>
            </div>)
        }
        else if(title.includes("840")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Deze refurbished laptop is uitermate geschikt voor dagelijks gebruik. De {title.split("|")[0]} is zowel te verkrijgen met een {cpu.split("@")[0]} processor. De laptop is standaard voorzien van {geheugen} geheugen en {ram} werkgeheugen. De Probook is voorzien van een {scherm} scherm. Technisch is de laptop in topconditie. De laptop is verkrijgbaar {conditie.toLowerCase()}.

            </p>
            </div>)
        }
        else if(title.includes("850")){

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                Met een {cpu.split("@")[0]}, {ram} RAM en {geheugen} geheugen is deze {title.split("|")[0]} geschikt voor professioneel gebruik. Deze laptop beschikt over een {scherm} scherm met een {gpu} grafische kaart. De refurbished conditie van deze laptop is {conditie.toLowerCase()} en technisch in topconditie.
                </p>
            </div>)
        }
        else{

            return(
            
            <div> 
                <h4>{product.id.content}</h4>
                <h3>{title}</h3>
                <p>
                
                </p>
            </div>)
        }


        

    }

    return(
        <Container ref={Itemref}>
            <h2>Laptops totaal:</h2>
            {itemfiles.item.map( (product, index) => (
                
                <Item>
                <h1>{index}</h1>
                {Checkfiles(product, index)}
                </Item>
            ))
            }

            
        </Container>
    )
}

export default Texten